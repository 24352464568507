import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.ts';
import type { RouteHandlers } from 'o365.pwa.declaration.sw.RouteHandlers.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const routeHandlers = self.o365.importScripts<RouteHandlers>("o365.pwa.modules.sw.RouteHandlers.ts");

    routeHandlers.initializeDefaultRouteHandlers();
})();
